<template>
    <v-container
        v-resize="onResize"
        fluid
        class="overflow-y-hidden pa-0"
        ref="container"
        :style="{ height: '100%' }"
    >
        <v-row ref="followUpHeader">
            <v-col class="ml-2 my-0 pa-0 d-flex align-center">
                <v-card-title
                    v-if="$vuetify.breakpoint.mdAndUp"
                    class="text-break"
                >
                    Main Board
                </v-card-title>
                <v-spacer></v-spacer>
                <v-btn small icon @click="openQuoteSchedule" color="primary">
                    <v-icon>
                        mdi-calendar
                    </v-icon>
                </v-btn>
                <v-card-title :style="{ 'max-width': '220px' }">
                    <v-combobox
                        prepend-icon="mdi-filter-outline"
                        v-model="usersFiltered"
                        :items="collaborators"
                        label="Users"
                        flat
                        solo
                        multiple
                        text
                        hide-details
                        @click="completeUsers"
                        v-if="seeFilters"
                    >
                        <template v-slot:selection="{ index }">
                            <div v-if="index === 0" class="mr-6">
                                <span>Users</span>
                            </div>
                            <v-badge
                                v-if="index === 0"
                                :content="usersFiltered.length"
                                :value="collaborators"
                                color="primary"
                                overlap
                                class="mt-n2"
                            >
                            </v-badge>
                        </template>
                    </v-combobox>
                </v-card-title>
            </v-col>
        </v-row>

        <v-progress-linear v-if="loading" color="primary" indeterminate />

        <v-divider class="mb-2"></v-divider>
        <v-card
            flat
            :class="{
                'overflow-x-auto': $vuetify.breakpoint.smAndDown,
                '': $vuetify.breakpoint.mdAndUp,
            }"
        >
            <div>
                <v-row class="flex-nowrap ma-0 pa-0">
                    <v-col
                        class="pl-2 pr-0 py-0 ma-0"
                        xl="4"
                        lg="4"
                        md="6"
                        sm="10"
                        xs="10"
                        cols="10"
                    >
                        <v-card flat class="ma-0 pa-0">
                            <v-card
                                :style="{ 'border-radius': '10px' }"
                                flat
                                color="primary"
                            >
                                <v-card-subtitle
                                    class="d-flex white--text font-weight-bold"
                                >
                                    <v-row no-gutters>
                                        <v-col cols="12" class="d-flex mb-n2">
                                            <div>
                                                <span
                                                    @click="
                                                        openQuotesBySection(
                                                            'Bidding'
                                                        )
                                                    "
                                                    :style="{
                                                        cursor: 'pointer',
                                                    }"
                                                >
                                                    Bidding ({{
                                                        filteredQuotesOpen.length
                                                    }})</span
                                                >
                                                <p>
                                                    {{
                                                        settings.length > 0
                                                            ? totalByStatus(
                                                                  filteredQuotesOpen
                                                              )
                                                            : '0'
                                                    }}
                                                </p>
                                            </div>
                                            <v-spacer />
                                            <v-dialog
                                                :retain-focus="false"
                                                v-model="createDialog"
                                                persistent
                                                max-width="500px"
                                            >
                                                <QuoteForm
                                                    title="New Quote"
                                                    :createForm="true"
                                                    @closeDialog="
                                                        closeCreateDialog
                                                    "
                                                    :settings="this.settings"
                                                    @addQuote="addQuote"
                                                />
                                            </v-dialog>
                                        </v-col>
                                        <v-col cols="12" class="">
                                            <v-row no-gutters>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        v-model="searchOpen"
                                                        backgroundColor="white"
                                                        class="black--text"
                                                        clearable
                                                        dense
                                                        filled
                                                        hide-details="auto"
                                                        placeholder="Search"
                                                        prepend-inner-icon="mdi-magnify"
                                                        rounded
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-subtitle>
                            </v-card>
                        </v-card>
                    </v-col>
                    <v-col
                        class="px-2 py-0 ma-0"
                        xl="4"
                        lg="4"
                        md="6"
                        sm="10"
                        xs="10"
                        cols="10"
                    >
                        <v-card flat class="ma-0 pa-0">
                            <v-card
                                :style="{ 'border-radius': '10px' }"
                                flat
                                color="orange"
                            >
                                <v-card-subtitle
                                    class="d-flex white--text font-weight-bold "
                                >
                                    <v-row no-gutters>
                                        <v-col
                                            cols="12"
                                            class="mb-1"
                                            :style="{ height: '38px' }"
                                        >
                                            <span
                                                @click="
                                                    openQuotesBySection(
                                                        'Approval'
                                                    )
                                                "
                                                :style="{ cursor: 'pointer' }"
                                            >
                                                Approval ({{
                                                    filteredQuotesApproval.length
                                                }})
                                            </span>
                                            <p>
                                                {{
                                                    settings.length > 0
                                                        ? totalByStatus(
                                                              filteredQuotesApproval
                                                          )
                                                        : '0'
                                                }}
                                            </p>
                                        </v-col>
                                        <v-col cols="12" class="mt-2">
                                            <v-row no-gutters>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        v-model="searchApproval"
                                                        backgroundColor="white"
                                                        class="black--text"
                                                        clearable
                                                        dense
                                                        filled
                                                        hide-details="auto"
                                                        placeholder="Search"
                                                        prepend-inner-icon="mdi-magnify"
                                                        rounded
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-subtitle>
                            </v-card>
                        </v-card>
                    </v-col>
                    <v-col
                        class="pr-2 pl-0 py-0 ma-0"
                        cols="10"
                        xl="4"
                        lg="4"
                        md="6"
                        sm="10"
                        xs="10"
                    >
                        <v-card flat class="ma-0 pa-0">
                            <v-card
                                :style="{ 'border-radius': '10px' }"
                                flat
                                color="warning"
                            >
                                <v-card-subtitle
                                    class="d-flex white--text font-weight-bold"
                                >
                                    <v-row no-gutters>
                                        <v-col
                                            cols="10"
                                            :style="{ height: '38px' }"
                                        >
                                            <span
                                                class="my-2 mr-2"
                                                @click="
                                                    openQuotesBySection(
                                                        'Follow Up'
                                                    )
                                                "
                                                :style="{ cursor: 'pointer' }"
                                            >
                                                Follow Up ({{
                                                    filteredQuotesSent.length
                                                }})
                                            </span>
                                            <p>
                                                {{
                                                    settings.length > 0
                                                        ? totalByStatus(
                                                              filteredQuotesSent
                                                          )
                                                        : 0
                                                }}
                                            </p>
                                        </v-col>
                                        <v-col
                                            class="d-flex justify-end"
                                            cols="2"
                                            :style="{ height: '38px' }"
                                        >
                                            <v-btn
                                                v-if="
                                                    filteredQuotesSent.length >
                                                        0
                                                "
                                                :disabled="loading"
                                                fab
                                                outlined
                                                small
                                                color="primary"
                                                @click="quotesByAgesDialog"
                                            >
                                                <v-icon>
                                                    mdi-chart-pie
                                                </v-icon>
                                            </v-btn>

                                            <v-dialog
                                                :retain-focus="false"
                                                v-model="openQuotesByAgesDialog"
                                                fullscreen
                                                ref="openQuotesByAgesDialog"
                                            >
                                                <v-card class="fill-height">
                                                    <v-card-title class="ml-n4">
                                                        <v-btn
                                                            icon
                                                            @click="
                                                                backToMainBoard
                                                            "
                                                        >
                                                            <v-icon
                                                                >mdi-close</v-icon
                                                            >
                                                        </v-btn>
                                                        Quotes By Ages
                                                    </v-card-title>
                                                    <v-card-text
                                                        class="px-0"
                                                        :style="
                                                            `height:${this.heightPopUp}px`
                                                        "
                                                    >
                                                        <router-view />
                                                    </v-card-text>
                                                </v-card>
                                            </v-dialog>
                                        </v-col>
                                        <v-col cols="12" class="mt-2">
                                            <v-row no-gutters>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        v-model="searchSent"
                                                        backgroundColor="white"
                                                        class="black--text"
                                                        clearable
                                                        dense
                                                        filled
                                                        hide-details="auto"
                                                        placeholder="Search"
                                                        prepend-inner-icon="mdi-magnify"
                                                        rounded
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-subtitle>
                            </v-card>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="flex-nowrap ma-0 pa-0">
                    <v-col
                        xl="4"
                        lg="4"
                        md="6"
                        sm="10"
                        xs="10"
                        cols="10"
                        class="overflow-y-auto pl-2 pr-0 py-0 ma-0"
                        :style="{ height: `${this.height - 142}px` }"
                    >
                        <v-col
                            cols="12"
                            class="pa-0 mx-0 my-3"
                            v-for="(quote, index) in filteredQuotesOpen"
                            :key="index"
                        >
                            <v-sheet
                                min-height="100"
                                class="fill-height"
                                color="transparent"
                            >
                                <v-lazy
                                    :v-model="quote"
                                    :options="{
                                        threshold: 0.5,
                                    }"
                                    class="fill-height"
                                >
                                    <CardQuote
                                        :key="quote.id"
                                        :quote="quote"
                                        :createHistory="createHistory"
                                        colorClass="primary--text"
                                        color="primary"
                                        :clients="tempClients"
                                        :contacts="tempContacts"
                                        @archiveDialog="archiveDialog"
                                        @quoteApproval="quoteApproval"
                                        @openQuote="openQuote"
                                        @closeDialog="closeDialog"
                                        @routeQuote="routeQuote"
                                        @loadCollaborator="loadCollaborator"
                                        @reOpenQuoteDialog="reOpenQuoteDialog"
                                    >
                                    </CardQuote>
                                </v-lazy>
                            </v-sheet>
                        </v-col>
                    </v-col>
                    <v-col
                        xl="4"
                        lg="4"
                        md="6"
                        sm="10"
                        xs="10"
                        cols="10"
                        class="overflow-y-auto px-2 py-0 ma-0"
                        :style="{ height: `${this.height - 142}px` }"
                    >
                        <v-col
                            cols="12"
                            class="pa-0 mx-0 my-3"
                            v-for="quote in filteredQuotesApproval"
                            :key="quote.id"
                        >
                            <v-sheet
                                min-height="100"
                                class="fill-height"
                                color="transparent"
                            >
                                <v-lazy
                                    :v-model="quote"
                                    :options="{
                                        threshold: 0.5,
                                    }"
                                    class="fill-height"
                                >
                                    <CardQuote
                                        :key="quote.id"
                                        :quote="quote"
                                        :createHistory="createHistory"
                                        colorClass="orange--text"
                                        color="orange"
                                        :clients="tempClients"
                                        :contacts="tempContacts"
                                        @archiveDialog="archiveDialog"
                                        @rejectedDialog="rejectedDialog"
                                        @openQuote="openQuote"
                                        @closeDialog="closeDialog"
                                        @routeQuote="routeQuote"
                                        @reOpenQuote="reOpenQuote"
                                        @loadCollaborator="loadCollaborator"
                                        @reOpenQuoteDialog="reOpenQuoteDialog"
                                    >
                                    </CardQuote>
                                </v-lazy>
                            </v-sheet>
                        </v-col>
                    </v-col>
                    <v-col
                        xl="4"
                        lg="4"
                        md="6"
                        sm="10"
                        xs="10"
                        cols="10"
                        class="overflow-y-auto pr-2 pl-0 py-0 ma-0"
                        :style="{ height: `${this.height - 142}px` }"
                    >
                        <v-col
                            cols="12"
                            class="pa-0 mx-0 my-3"
                            v-for="quote in filteredQuotesSent"
                            :key="quote.id"
                        >
                            <v-sheet
                                min-height="100"
                                class="fill-height"
                                color="transparent"
                            >
                                <v-lazy
                                    :v-model="quote"
                                    :options="{
                                        threshold: 0.5,
                                    }"
                                    class="fill-height"
                                >
                                    <CardQuote
                                        :key="quote.id"
                                        :quote="quote"
                                        :createHistory="createHistory"
                                        colorClass="warning--text"
                                        color="warning"
                                        :clients="tempClients"
                                        :contacts="tempContacts"
                                        :settings="settings"
                                        @archiveDialog="archiveDialog"
                                        @rejectedDialog="rejectedDialog"
                                        @openQuote="openQuote"
                                        @closeDialog="closeDialog"
                                        @routeQuote="routeQuote"
                                        @reOpenQuote="reOpenQuote"
                                        @quoteAwarded="quoteAwarded"
                                        @loadCollaborator="loadCollaborator"
                                        @reOpenQuoteDialog="reOpenQuoteDialog"
                                        @modificationRequestProcess="
                                            modificationRequestProcess
                                        "
                                    >
                                    </CardQuote>
                                </v-lazy>
                            </v-sheet>
                        </v-col>
                    </v-col>
                </v-row>
            </div>
        </v-card>
        <!--QUOTE-->
        <v-dialog
            :retain-focus="false"
            v-model="dialogQuote"
            fullscreen
            ref="dialogQuote"
        >
            <v-card v-if="dialogQuote" class="fill-height">
                <v-card-title class="ml-n4">
                    <v-btn icon @click="backToMainBoard">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    Quote
                </v-card-title>
                <v-card-text
                    class="px-0"
                    :style="{ height: `${this.heightPopUp}px` }"
                >
                    <Quote
                        :id="cardQuote.id"
                        :quote="cardQuote"
                        :settings="settings"
                        :history="false"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- Quotes By Section -->
        <v-dialog
            :retain-focus="false"
            v-model="dialogQuotesBySection"
            fullscreen
            ref="dialogQuotesBySection"
        >
            <v-card class="fill-height">
                <v-card-title class="ml-n4">
                    <v-btn icon @click="backToMainBoard">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    {{ section }}
                </v-card-title>
                <v-card-text
                    class="px-0"
                    :style="{ height: `${this.heightPopUp}px` }"
                >
                    <router-view />
                </v-card-text>
            </v-card>
        </v-dialog>
        <!--Archive Quote-->
        <v-dialog max-width="300px" v-model="archive">
            <v-card :loading="loadingArchive">
                <v-card-title class="text-h5">Archive Quote</v-card-title>
                <v-card-text>
                    Are you sure you want to archive this quote?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="archive = false">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="archiveQuote"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Archive
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Lost Quote-->
        <v-dialog max-width="300px" v-model="rejected">
            <v-card :loading="loadingArchive">
                <v-card-title class="text-h5">Lost Quote</v-card-title>
                <v-card-text>
                    Are you sure you want to change the status of this quote?

                    <v-form ref="formReason" v-model="valid" class="mt-7">
                        <v-autocomplete
                            v-model="rejectReason"
                            :items="this.reasons"
                            :rules="[rules.required]"
                            prepend-icon="mdi-text"
                            item-text="reason"
                            item-value="id"
                            label="Reason*"
                            required
                        />
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-btn text color="secondary" @click="rejected = false">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="rejectedQuote"
                        :disabled="!valid || error"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Lost
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-alert
            :value="successEmail"
            :style="{ position: 'fixed', bottom: '30px', right: 0 }"
            color="blue"
            dismissible
            type="success"
            transition="scale-transition"
        >
            Quote was successfully sent
        </v-alert>
        <!--Quote Schedule-->
        <v-dialog
            :retain-focus="false"
            persistent
            fullscreen
            v-model="openSchedule"
        >
            <QuoteSchedule
                v-if="openSchedule"
                :quotes="quotesByCalendar"
                :settings="settings"
                @close="closeQuoteSchedule"
            />
        </v-dialog>
        <!--CONFIRM AWARDED-->
        <v-dialog
            :retain-focus="false"
            persistent
            max-width="600px"
            v-model="confirmAwardedDialog"
        >
            <ConfirmAwarded
                v-if="confirmAwardedDialog"
                :quote="selectedQuote"
                @close="closeConfirmAwarded"
                @updateChanges="updateChanges"
            />
        </v-dialog>
        <!--SEND FOR MODIFICATION FROM APPROVAL-->
        <v-dialog
            :retain-focus="false"
            persistent
            max-width="600px"
            v-model="sendToModificationFromApproval"
        >
            <ReasonsForNotApproving
                v-if="sendToModificationFromApproval"
                :quote="selectedQuote"
                :userId="userId"
                @close="closeReOpenQuoteDialog"
                @reOpenQuote="reOpenQuote"
            />
        </v-dialog>
        <v-dialog
            :retain-focus="false"
            v-model="quoteFormDialog"
            persistent
            max-width="500px"
        >
            <QuoteModifications
                v-if="quoteFormDialog"
                :originalQuote="selectedQuote"
                :settings="settings"
                :loading2="loading2"
                @closeDialog="closeQuoteModifications"
                @modificationRequest="modificationRequest"
            />
        </v-dialog>
    </v-container>
</template>

<script>
import API from '@/services/api'
import _ from 'lodash'
import CardQuote from '@/components/Quotes/CardQuote'
import moment from 'moment'
import { storage } from '@/services/firebase'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'

export default {
    name: 'MainBoard',
    props: {
        resourceId: {
            type: String,
            default: () => '',
        },
    },
    components: {
        CardQuote,
        Quote: () => import('@/views/Quotes/Quote.vue'),
        QuoteSchedule: () => import('@/components/Quotes/QuoteSchedule.vue'),
        ConfirmAwarded: () => import('@/components/Quotes/ConfirmAwarded.vue'),
        ReasonsForNotApproving: () =>
            import('@/components/Quotes/ReasonsForNotApproving.vue'),
        QuoteForm: () => import('@/components/Quotes/QuoteForm'),
        QuoteModifications: () =>
            import('@/components/Quotes/QuoteModifications'),
    },
    data() {
        return {
            sendToModificationFromApproval: false,
            createQuotes: false,
            loading: false,
            loading2: false,
            loadingArchive: false,
            loadingError: false,
            errorMsg: null,
            error: null,
            valid: false,
            costs: {},
            archive: false,
            dialogQuote: false,
            dialogQuoteOpen: false,
            cardQuote: null,
            folderQuotes: 'quotes_documents',
            companyId: JSON.parse(localStorage.getItem('company')),
            userId: JSON.parse(localStorage.getItem('userId')),
            user: JSON.parse(localStorage.getItem('user')),
            folderClientsPictures: 'clients_pictures',
            folderUsersPictures: 'users_pictures',
            folderDocuments: 'quotes_documents',
            folderImages: 'photo_galery',
            quotesOpen: [],
            quotesSent: [],
            quotesApproval: [],
            quoteToArchive: {},
            archiveError: false,
            rejectReason: null,
            reasons: [],
            rejected: false,
            folderLogo: 'company_logo',
            headers: [
                {
                    text: 'ID',
                    value: 'id',
                    class: 'grey lighten-3',
                },
                {
                    text: 'DESCRIPTION',
                    value: 'reference',
                    class: 'grey lighten-3',
                },
                {
                    text: 'QUANTITY',
                    value: 'quantity',
                    class: 'grey lighten-3',
                },
                {
                    text: 'PRICE PER QUANTITY',
                    value: 'pricePerQuantity2',
                    class: 'grey lighten-3',
                },
                {
                    text: 'SALE PRICE',
                    value: 'salePrice2',
                    class: 'grey lighten-3',
                },
            ],
            headersArchivedQuotes: [
                {
                    text: 'ID',
                    value: 'id',
                    class: 'grey lighten-3',
                },
                {
                    text: 'NAME',
                    value: 'name',
                    class: 'grey lighten-3',
                },
                {
                    text: 'CLIENT',
                    value: 'client.name',
                    class: 'grey lighten-3',
                },
                {
                    text: 'COST',
                    value: 'totalCosts',
                    class: 'grey lighten-3',
                },
            ],
            dialogSend: false,
            searchOpen: null,
            searchSent: null,
            searchAwarded: null,
            searchApproval: null,
            height: 0,
            successEmail: false,
            tempClients: [],
            tempContacts: [],
            collaborators: [],
            usersFiltered: [],
            editAwardedQuote: false,
            createDialog: false,
            heightPopUp: 0,
            dialogQuotesBySection: false,
            section: '',
            seeFilters: false,
            createHistory: false,
            users: [],
            openQuotesByAgesDialog: false,
            rules: {
                required: v => !!v || 'Required',
            },
            openSchedule: false,
            confirmAwardedDialog: false,
            selectedQuote: {},
            quoteFormDialog: false,
            updatedQuote: false,
        }
    },
    provide() {
        return {
            replaceQuote: this.replaceQuote,
            closeSendQuote: this.closeSendQuote,
            addQuoteForApproval: this.addQuoteForApproval,
            archiveQuote: this.archiveQuote,
            backToMainRoute: this.backToMainBoard,
            openQuoteFromTable: this.openQuoteFromTable,
            filterQuotes: this.filterQuotes,
            rejectedDialog: this.rejectedDialog,
            quoteAwarded: this.quoteAwarded,
            reOpenQuote: this.reOpenQuote,
            modificationRequestProcess: this.modificationRequestProcess,
        }
    },
    computed: {
        ...mapState([
            'quotes',
            'historyQuotes',
            'settings',
            'clients',
            'contacts',
            'historyCosts',
            'userRef',
        ]),
        ...mapGetters([
            'getClientQuote',
            'getSelectedQuoteInGeneral',
            'getNotificationResource',
        ]),
        quotesByCalendar() {
            return [
                ...this.filteredQuotesOpen,
                ...this.filteredQuotesApproval,
                ...this.filteredQuotesSent,
            ]
        },
        filteredQuotesOpen: function() {
            return this.filterQuotesBySearch(this.quotesOpen, this.searchOpen)
        },
        filteredQuotesApproval: function() {
            return this.filterQuotesBySearch(
                this.quotesApproval,
                this.searchApproval
            )
        },
        filteredQuotesSent: function() {
            return this.filterQuotesBySearch(this.quotesSent, this.searchSent)
        },
    },
    watch: {
        value() {
            if (
                typeof this.usersFiltered[this.usersFiltered.length - 1] ===
                'string'
            ) {
                this.usersFiltered.pop()
            }
        },
        getSelectedQuoteInGeneral() {
            if (this.getSelectedQuoteInGeneral) {
                const quote = this.quotes.find(
                    q => q.id === this.getSelectedQuoteInGeneral?.id
                )
                if (quote) {
                    this.openQuote(quote)
                    this.setSelectedQuoteInGeneral(undefined)
                } else {
                    this.openQuote(this.getSelectedQuoteInGeneral)
                    this.setSelectedQuoteInGeneral(undefined)
                }
            }
        },
        getNotificationResource() {
            const quote = this.quotes.find(
                q => q.id === this.getNotificationResource
            )
            if (quote) {
                this.openQuote(quote)
                this.setNotificationResource(undefined)
            }
        },
    },
    methods: {
        ...mapActions([
            'addQuotesAction',
            'addSettingsAction',
            'addUsersAction',
            'addHistoryQuotesAction',
            'addContactsAction',
            'addClientsAction',
            'replaceQuoteAction',
            'removeQuoteAction',
            'addHistoryCostAction',
            'setTemQuoteAction',
            'setFromMainBoardAction',
            'addQuoteAction',
            'updateQuoteAction',
        ]),
        ...mapMutations([
            'setClientQuote',
            'setSelectedQuoteInGeneral',
            'setErrorItems',
            'setNotificationResource',
        ]),
        closeQuoteSchedule() {
            this.openSchedule = false
        },
        openQuoteSchedule() {
            this.openSchedule = true
        },
        filterQuotesBySearch(quotes, searchParam) {
            this.setClients(quotes)
            let quotesByCollaborators = []
            this.usersFiltered.forEach(element => {
                quotes.forEach(quote => {
                    if (!quotesByCollaborators.find(t => t.id === quote.id)) {
                        if (
                            quote.collaborators &&
                            (quote.collaborators.includes(element.value) ||
                                quote.userId === element.value)
                        ) {
                            quotesByCollaborators.push(quote)
                        }
                    }
                })
            })
            if (
                this.usersFiltered.length == 0 &&
                quotesByCollaborators.length == 0
            ) {
                quotesByCollaborators = _.cloneDeep(quotes)
            }
            let quotesAux = []
            quotesByCollaborators.forEach(quote => {
                if (
                    quote.name
                        .toLowerCase()
                        .includes(searchParam?.toLowerCase()) ||
                    quote.client?.name
                        .toLowerCase()
                        .includes(searchParam?.toLowerCase()) ||
                    quote.number
                        ?.toString()
                        .includes(searchParam?.toLowerCase())
                ) {
                    quotesAux.push(quote)
                }
            })
            if (searchParam) {
                return quotesAux
            }
            return quotesByCollaborators
        },
        completeUsers() {
            this.users.forEach(user => {
                if (!this.collaborators.find(c => c.value === user.id)) {
                    this.collaborators.push({
                        text: user.name,
                        value: user.id,
                    })
                }
            })
        },
        openQuotesBySection(section) {
            this.dialogQuotesBySection = true
            this.section = section
            if (section.toLowerCase() === 'bidding') {
                this.$router.push({
                    name: 'quotesByStatus',
                    params: {
                        quotes: this.restructureQuotes(this.filteredQuotesOpen),
                    },
                })
            }

            if (section.toLowerCase() === 'approval') {
                this.$router.push({
                    name: 'quotesByStatus',
                    params: {
                        quotes: this.restructureQuotes(
                            this.filteredQuotesApproval
                        ),
                    },
                })
            }

            if (section.toLowerCase() === 'follow up') {
                this.$router.push({
                    name: 'quotesByStatus',
                    params: {
                        type: 'followUp',
                        quotes: this.restructureQuotes(this.filteredQuotesSent),
                        settings: this.settings,
                    },
                })
            }
        },
        setCollaborators(quotes) {
            quotes.forEach(quote => {
                if (!quote.dataCollaborators) {
                    quote.dataCollaborators = []
                    quote.collaborators.forEach(element => {
                        const dataUser = this.users.find(
                            user => element == user.id
                        )
                        if (dataUser) quote.dataCollaborators.push(dataUser)
                    })
                }
            })
        },
        setMainContact(quotes) {
            quotes.forEach(quote => {
                if (!quote.mainContact) {
                    quote.mainContact = this.users.find(
                        user => quote.userId == user.id
                    )
                }
            })
        },

        setClients(quotes) {
            quotes.forEach(quote => {
                let client = undefined
                if (quote.clientId && !quote.client) {
                    client = this.tempClients.find(
                        client => quote.clientId == client.id
                    )
                    quote.clients = this.tempClients
                    quote.client = client
                }
            })
        },

        restructureQuotes(quotes) {
            this.setMainContact(quotes)
            this.setCollaborators(quotes)
            this.setClients(quotes)
            return quotes.map(q => {
                const filteredColaborators = q.dataCollaborators.filter(
                    colaborator => colaborator.id != q.userId
                )
                return {
                    quoteId: q.id,
                    id: q.number,
                    client: q.client ? q.client.name : '',
                    project: q.name,
                    country: q.country,
                    city: q.city,
                    price: q.totalCosts,
                    currency: q.currency,
                    exchange: q.exchange,
                    accountManager: q.mainContact?.name || '',
                    collaborators:
                        q.dataCollaborators === undefined
                            ? []
                            : filteredColaborators.map(
                                  collaborator => collaborator?.name
                              ),
                    tags: q.tags || [],
                    probability: q.probability,
                    expectedDate: q.expectedDate,
                    sentDate: q.sentDate,
                    updatedOn: q.lastUpdate,
                }
            })
        },
        totalByStatus(quotes) {
            if (quotes.length > 0) {
                const exchange = this.settings.find(s => s.name === 'Exchange')
                if (!exchange) {
                    return null
                }
                let res = quotes.reduce((acumulator, quote) => {
                    if (quote.totalCosts && exchange.exchanges) {
                        const rate = exchange.exchanges.find(
                            e => e.currency === quote.currency
                        )
                        const conversion =
                            exchange.globalCurrency == quote.currency
                                ? quote.totalCosts
                                : quote.totalCosts * parseFloat(rate.money)
                        return parseFloat((acumulator + conversion).toFixed(0))
                    } else {
                        return acumulator + 0
                    }
                }, 0)
                if (exchange) {
                    return new Intl.NumberFormat('de-DE', {
                        style: 'currency',
                        currency: exchange.globalCurrency,
                        minimumFractionDigits: 0,
                    }).format(res)
                }
            } else {
                return 0
            }
        },
        loadCollaborator(users) {
            if (users.length > 0) {
                users.forEach(user => {
                    this.collaborators.push({
                        text: user.name,
                        value: user.id,
                    })
                })
            }
        },
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                followUpHeader: { clientHeight: headerHeight },
                // followUpSections: { clientHeight: sectionHeight }
            } = this.$refs
            this.height = containerHeight - headerHeight + 19
            this.heightPopUp = window.innerHeight - 62
        },
        getQuotes: async function() {
            try {
                this.loadingError = false
                this.errorMsg = null
                this.loading = true
                const {
                    data: { quotes },
                } = await API.getQuotes({})
                const {
                    data: { settings },
                } = await API.getSettings()
                const indexReasons = settings.findIndex(x => x.name == 'Quotes')
                if (settings[indexReasons].rejectionReasons) {
                    this.reasons = settings[indexReasons].rejectionReasons
                }
                const indexLogo = settings.findIndex(x => x.name == 'Company')
                if (settings[indexLogo].generalInfo.image) {
                    const path = `${this.companyId}/${this.folderLogo}`
                    const storageRef = storage().ref(path)
                    const profilePicRef = storageRef.child(
                        settings[indexLogo].generalInfo.image.file
                    )
                    settings[
                        indexLogo
                    ].generalInfo.image.imageURL = await profilePicRef.getDownloadURL()
                }
                for (const quote of quotes) {
                    quote.attached = quote.files?.filter(x => x.attach == true)
                    quote.docs = quote.files?.filter(x => x.attach == false)
                    quote.sequence = quote.createdOn._seconds
                    if (!quote.number) {
                        quote.shortId = quote.createdOn._seconds
                            .toString(16)
                            .toUpperCase()
                    }
                    quote.date = moment
                        .unix(quote.createdOn._seconds)
                        .format('MMM/DD/YYYY')
                    quote.createdOn = moment
                        .unix(quote.createdOn._seconds)
                        .format('MMM/DD/YYYY h:mm a')
                    quote.lastUpdate = quote.updatedOn
                    if (quote.updatedOn) {
                        quote.updatedOn = moment
                            .unix(quote.updatedOn._seconds)
                            .format('MMM/DD/YYYY h:mm a')
                    }

                    if (quote.status.toLowerCase() === 'open') {
                        this.quotesOpen.push(quote)
                    }
                    if (quote.status.toLowerCase() === 'approval') {
                        this.quotesApproval.push(quote)
                    }
                    if (quote.status.toLowerCase() === 'sent') {
                        this.quotesSent.push(quote)
                    }
                }
                this.quotesOpen.sort((a, b) => b.seconds - a.seconds)
                this.quotesApproval.sort((a, b) => b.seconds - a.seconds)
                this.quotesSent.sort((a, b) => b.seconds - a.seconds)
                this.addQuotesAction(quotes)
                let orderSettings = settings.filter(x => x.name == 'Company')
                orderSettings[1] = settings.find(x => x.name == 'Exchange')
                orderSettings[2] = settings.find(x => x.name == 'Permissions')
                orderSettings[3] = settings.find(x => x.name == 'Quotes')

                const calculations = this.userRef.permissions.find(
                    x => x == 'calculations'
                )

                if (calculations) {
                    const item = settings.find(x => x.name == 'Calculations')
                    if (item) {
                        orderSettings[4] = item
                    }
                }

                const processes = settings.find(x => x.name == 'Processes')
                const products = settings.find(x => x.name == 'Products')
                const projectsSettings = settings.find(
                    x => x.name == 'Projects'
                )
                if (projectsSettings) {
                    const projectsTab = {
                        name: 'Projects',
                        nameRoute: 'projectsSettings',
                        projectsSettings,
                    }
                    orderSettings.push(projectsTab)
                }

                const finish = settings.find(x => x.name == 'FinishAndUCCode')
                if (finish) {
                    orderSettings.push(finish)
                }

                const invoicing = settings.find(x => x.name == 'Invoicing')
                if (invoicing) {
                    orderSettings.push(invoicing)
                }

                if (processes) {
                    const machines = {
                        name: 'Machines',
                        nameRoute: 'machinesSettings',
                        id: processes.id,
                    }
                    orderSettings.push(machines)
                }

                const workOrderTab = {
                    name: 'WorkOrders',
                    nameRoute: 'workOrderSettings',
                    processes: processes || {},
                    products: products || {},
                }
                orderSettings.push(workOrderTab)
                const operatorsTab = {
                    name: 'Operators',
                    nameRoute: 'operators',
                }
                orderSettings.push(operatorsTab)
                this.addSettingsAction(orderSettings)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                // validate quote from general search
                if (
                    this.getSelectedQuoteInGeneral ||
                    this.getNotificationResource ||
                    this.$route.query.resourceId
                ) {
                    const quote = this.quotes.find(
                        q =>
                            q.id ===
                            (this.getNotificationResource ||
                                this.$route.query.resourceId.trim() ||
                                this.getSelectedQuoteInGeneral?.id)
                    )
                    if (quote) {
                        this.openQuote(quote, this.costs)
                        this.setSelectedQuoteInGeneral(undefined)
                        this.setNotificationResource(undefined)
                    } else if (this.getSelectedQuoteInGeneral) {
                        this.openQuote(
                            this.getSelectedQuoteInGeneral,
                            this.costs
                        )
                        this.setSelectedQuoteInGeneral(undefined)
                    }
                }
            }
        },
        getClients: async function() {
            try {
                this.loadingError = false
                this.errorMsg = null
                this.loading = true
                const {
                    data: { clients },
                } = await API.getLiteClients({ companyId: this.companyId })
                this.tempClients = clients
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        getUsers: async function() {
            try {
                this.loadingError = false
                this.errorMsg = null
                this.loading = true
                const {
                    data: { users },
                } = await API.getLiteUsers()
                this.users = users
                this.addUsersAction(users)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        rejectedDialog(quote) {
            this.rejected = true
            this.quoteToArchive = _.cloneDeep(quote)
        },
        archiveDialog(quote) {
            this.archive = true
            this.quoteToArchive = _.cloneDeep(quote)
        },
        getColor(status) {
            if (status == 'OPEN') return 'primary'
            else if (status == 'APPROVAL') return 'success'
            else if (status == 'LOST') return 'error'
            else if (status == 'SENT') return 'warning'
            else return 'info'
        },
        rejectedQuote: async function() {
            try {
                const status = this.quoteToArchive.status
                if (status == 'SENT') {
                    const index = this.quotesSent.findIndex(
                        x => x.id == this.quoteToArchive.id
                    )
                    this.quotesSent.splice(index, 1)
                } else if (status == 'APPROVAL') {
                    const index = this.quotesApproval.findIndex(
                        x => x.id == this.quoteToArchive.id
                    )
                    this.quotesApproval.splice(index, 1)
                }
                this.quoteToArchive.status = 'LOST'
                this.loadingArchive = true
                this.archiveError = false
                this.errorMsg = null
                const { id } = this.quoteToArchive
                const {
                    data: { history },
                } = await API.markQuoteAsLost(
                    id,
                    this.quoteToArchive.status,
                    this.rejectReason,
                    'FMGcnvQw2J1b4kpidwr4'
                )
                history.createdOn = moment
                    .unix(history.createdOn._seconds)
                    .format('MMM/DD/YYYY h:mm a')
                if (history.updatedOn) {
                    history.updatedOn = moment
                        .unix(history.updatedOn._seconds)
                        .format('MMM/DD/YYYY h:mm a')
                }
                history.status = 'LOST'
                history.users = this.quoteToArchive.users
                history.clients = this.quoteToArchive.clients
                history.client = this.quoteToArchive.client
                history.mainContact = this.quoteToArchive.mainContact
                history.dataCollaborators = this.quoteToArchive.dataCollaborators
                //await API.deleteQuote(id, this.quoteToArchive)
                this.removeQuoteAction(id)
                // if (this.historyQuotes.length > 0) {
                //     this.historyQuotes.push(history)
                // }
                this.rejectReason = null
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loadingArchive = false
                this.rejected = false
            }
        },
        archiveQuote: async function(quote = undefined) {
            try {
                if (quote && quote.id) {
                    this.quoteToArchive = _.cloneDeep(quote)
                }
                const status = this.quoteToArchive.status
                if (status == 'OPEN') {
                    const index = this.quotesOpen.findIndex(
                        x => x.id == this.quoteToArchive.id
                    )
                    this.quotesOpen.splice(index, 1)
                } else if (status == 'SENT') {
                    const index = this.quotesSent.findIndex(
                        x => x.id == this.quoteToArchive.id
                    )
                    this.quotesSent.splice(index, 1)
                } else if (status == 'APPROVAL') {
                    const index = this.quotesApproval.findIndex(
                        x => x.id == this.quoteToArchive.id
                    )
                    this.quotesApproval.splice(index, 1)
                }
                this.loadingArchive = true
                this.archiveError = false
                this.errorMsg = null
                const { id } = this.quoteToArchive
                const {
                    data: { history },
                } = await API.createQuoteHistory(
                    id,
                    undefined,
                    undefined,
                    'pFDh25EcR74EMiuypq8M'
                )
                history.createdOn = moment
                    .unix(history.createdOn._seconds)
                    .format('MMM/DD/YYYY h:mm a')
                if (history.updatedOn) {
                    history.updatedOn = moment
                        .unix(history.updatedOn._seconds)
                        .format('MMM/DD/YYYY h:mm a')
                }
                history.users = this.quoteToArchive.users
                history.clients = this.quoteToArchive.clients
                history.client = this.quoteToArchive.client
                history.mainContact = this.quoteToArchive.mainContact
                history.dataCollaborators = this.quoteToArchive.dataCollaborators
                this.removeQuoteAction(id)
                // if (this.historyQuotes.length > 0) {
                //     this.historyQuotes.push(history)
                // }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loadingArchive = false
                this.archive = false
            }
        },
        saveQuote: async function(element) {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const { id } = element
                await API.updateQuote(id, {
                    notificationId: 'LrubMkCSfpNwEoXemQKL',
                    ...element,
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        quoteAwarded: function(quote) {
            try {
                //open confirm dialog
                this.confirmAwardedDialog = true
                this.selectedQuote = _.cloneDeep(quote)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        openFile: async function(id, file) {
            const path = `${this.companyId}/${this.folderDocuments}/${id}`
            const storageRef = storage().ref(path)
            const documentRef = storageRef.child(file)
            const url = await documentRef.getDownloadURL()
            if (this.$device.ios) {
                window.location.assign(url)
            } else {
                window.open(url, '_blank')
            }
        },
        routeQuote: function(item) {
            if (item.status !== 'OPEN') {
                this.setTemQuoteAction(item)
            }
            this.$router.push({
                name: 'quote',
                params: {
                    id: item.id,
                    quote: item,
                    settings: this.settings,
                },
            })
        },
        async openQuoteFromTable(quoteId) {
            const quote = this.quotes.find(quote => quote.id === quoteId)
            return quote
        },
        async openQuote(quote, costs) {
            if (this.settings.length > 0) {
                this.cardQuote = _.cloneDeep(quote)
                if (this.cardQuote.archive) {
                    this.cardQuote.attached = this.cardQuote.files.filter(
                        x => x.attach == true
                    )
                    this.cardQuote.docs = this.cardQuote.files.filter(
                        x => x.attach == false
                    )
                    this.cardQuote.sequence =
                        this.cardQuote.createdOn._seconds ||
                        this.cardQuote.createdOn.seconds
                    if (!this.cardQuote.number) {
                        this.cardQuote.shortId = (
                            this.cardQuote.createdOn._seconds ||
                            this.cardQuote.createdOn.seconds
                        )
                            .toString(16)
                            .toUpperCase()
                    }
                    this.cardQuote.date = moment
                        .unix(
                            this.cardQuote.createdOn._seconds ||
                                this.cardQuote.createdOn.seconds
                        )
                        .format('MMM/DD/YYYY')
                    this.cardQuote.createdOn = moment
                        .unix(
                            this.cardQuote.createdOn._seconds ||
                                this.cardQuote.createdOn.seconds
                        )
                        .format('MMM/DD/YYYY h:mm a')
                    this.cardQuote.updatedOn = moment
                        .unix(
                            this.cardQuote.updatedOn._seconds ||
                                this.cardQuote.updatedOn.seconds
                        )
                        .format('MMM/DD/YYYY h:mm a')
                }
                this.costs = costs
                await this.getHistoryCosts()
                this.setFromMainBoardAction(true)
                this.dialogQuote = true
            }
        },
        openSendQuote() {
            this.dialogSend = true
        },
        activateSuccessAlert() {
            this.successEmail = true
        },
        closeSendQuote() {
            this.dialogSend = false
            const index = this.quotesApproval.findIndex(
                quote => quote.id === this.cardQuote.id
            )
            if (index >= 0) {
                this.cardQuote.number =
                    parseInt(this.cardQuote.number * 100 + 1) / 100
                this.quotesApproval.splice(index, 1)
                this.cardQuote.status = 'SENT'
                this.quotesSent.splice(0, 0, this.cardQuote)
                this.updateQuoteAction({
                    id: this.cardQuote.id,
                    quote: this.cardQuote,
                })
            }
            this.backToMainBoard()
            this.activateSuccessAlert()
        },
        closeDialog() {
            this.dialogQuote = false
        },
        filterQuotes() {
            this.quotesSent = this.quotes.filter(
                quote => quote.status == 'SENT'
            )
            this.quotesOpen = this.quotes.filter(
                quote => quote.status === 'OPEN'
            )
            this.quotesApproval = this.quotes.filter(
                quote => quote.status === 'APPROVAL'
            )
        },
        closeReOpenQuoteDialog() {
            this.sendToModificationFromApproval = false
            this.selectedQuote = {}
        },
        reOpenQuoteDialog(quote) {
            this.selectedQuote = _.cloneDeep(quote)
            this.sendToModificationFromApproval = true
        },
        reOpenQuote: async function(quote) {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const { id, ...copyQuote } = _.cloneDeep(quote)
                copyQuote.status = 'OPEN'
                await API.updateQuote(id, {
                    notificationId: 'LrubMkCSfpNwEoXemQKL',
                    status: 'OPEN',
                })
                const index = this.quotesSent.findIndex(
                    quote => quote.id === id
                )
                if (index == -1) {
                    const indexApproval = this.quotesApproval.findIndex(
                        quote => quote.id === id
                    )
                    this.quotesApproval.splice(indexApproval, 1)
                } else {
                    this.quotesSent.splice(index, 1)
                }
                this.quotesOpen.splice(0, 0, Object.assign(copyQuote, { id }))
                copyQuote.id = id
                this.replaceQuoteAction({ id, quote: copyQuote })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        modificationRequestProcess: async function(quote) {
            try {
                this.error = false
                this.errorMsg = null
                this.selectedQuote = _.cloneDeep(quote)
                this.quoteFormDialog = true
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeQuoteModifications() {
            this.quoteFormDialog = false
            this.selectedQuote = {}
        },
        modificationRequest: async function({ quoteUpdates, message, files }) {
            try {
                this.loading2 = true
                const { id, ...copyQuote } = _.cloneDeep(this.selectedQuote)

                if (quoteUpdates.city) {
                    if (typeof quoteUpdates.city == 'object') {
                        quoteUpdates.city = quoteUpdates.city.text
                    }
                }
                if (quoteUpdates.preconManagerId) {
                    await API.changePreconManager({
                        id: this.selectedQuote.id,
                        preconManagerId: quoteUpdates.preconManagerId,
                    })
                }
                //modification request
                quoteUpdates.files = files || []
                await API.modificationRequest(
                    this.selectedQuote.id,
                    quoteUpdates
                )

                //send message in chat
                const keys = Object.keys(quoteUpdates)
                let keyString = ''

                keys.forEach((key, index) => {
                    keyString += key
                    if (index < keys.length - 1) {
                        keyString += ', '
                    }
                })

                await API.createPost({
                    chatId: this.selectedQuote.id,
                    userId: this.userId,
                    message: `New modification request ${
                        keyString.length > 0 ? ` updating: ${keyString}` : ''
                    }. ${message}`,
                })

                // change quote column
                const index = this.quotesSent.findIndex(
                    quote => quote.id === id
                )
                //TODO: add updated keys
                this.quotesSent.splice(index, 1)
                this.replaceQuoteAction({ id, quote: copyQuote })
                this.closeQuoteModifications()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading2 = false
            }
        },
        quoteApproval: async function(quote) {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const { id, ...copyQuote } = _.cloneDeep(quote)
                copyQuote.status = 'APPROVAL'
                await API.updateQuote(id, {
                    notificationId: 'LrubMkCSfpNwEoXemQKL',
                    status: 'APPROVAL',
                })
                const index = this.quotesOpen.findIndex(
                    quote => quote.id === id
                )
                this.quotesOpen.splice(index, 1)
                this.quotesApproval.splice(
                    0,
                    0,
                    Object.assign(copyQuote, { id })
                )
                this.replaceQuoteAction({ id, quote: copyQuote })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        addQuoteForApproval: function(quote) {
            const indexOfOpenQuotes = this.quotesOpen.findIndex(
                q => q.id === quote.id
            )
            if (indexOfOpenQuotes == -1) {
                const indexOfApprovalQuotes = this.quotesApproval.findIndex(
                    q => q.id === quote.id
                )
                this.quotesApproval.splice(indexOfApprovalQuotes, 1, quote)
            } else {
                this.quotesOpen.splice(indexOfOpenQuotes, 1)
                this.quotesApproval.splice(0, 0, quote)
            }
        },
        addQuote: function(quote) {
            const copyQuote = _.clone(quote)
            copyQuote.attached = copyQuote.files.filter(x => x.attach == true)
            copyQuote.docs = copyQuote.files.filter(x => x.attach == false)
            if (!copyQuote.number) {
                copyQuote.shortId = copyQuote.createdOn._seconds
                    .toString(16)
                    .toUpperCase()
            }
            copyQuote.date = moment
                .unix(copyQuote.createdOn._seconds)
                .format('MMM DD YYYY ')
            copyQuote.createdOn = moment
                .unix(copyQuote.createdOn._seconds)
                .format('MMM/DD/YYYY h:mm a')

            if (copyQuote.updatedOn) {
                copyQuote.updatedOn = moment
                    .unix(copyQuote.updatedOn._seconds)
                    .format('MMM/DD/YYYY h:mm a')
            }
            if (copyQuote.clientId) {
                copyQuote.client = this.tempClients.find(
                    client => copyQuote.clientId == client.id
                )
                copyQuote.client.contacts = this.tempContacts.filter(
                    contact => contact.clientId == copyQuote.clientId
                )
            }
            if (copyQuote.userId) {
                copyQuote.mainContact = this.users.find(
                    user => copyQuote.userId == user.id
                )
            }
            copyQuote.clients = this.tempClients
            copyQuote.users = this.users
            this.quotesOpen.splice(0, 0, copyQuote)
            this.addQuoteAction(copyQuote)
        },
        //
        getHistoryCosts: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const {
                    data: { historyCosts },
                } = await API.getHistoryCosts(this.cardQuote.id)
                if (historyCosts.length > 0) {
                    historyCosts.forEach(cost => {
                        cost.createdOn = moment
                            .unix(cost.createdOn._seconds)
                            .format('MMM/DD/YYYY h:mm a')
                    })

                    this.addHistoryCostAction({
                        idQuote: this.cardQuote.id,
                        historyCosts: historyCosts,
                    })
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        openCreateDialog: function() {
            this.createDialog = true
        },
        closeCreateDialog: function() {
            this.createDialog = false
        },
        backToMainBoard: function() {
            this.dialogQuote = false
            this.dialogQuotesBySection = false
            this.openQuotesByAgesDialog = false
            this.setFromMainBoardAction(false)
            this.$router
                .replace({
                    name: 'main board',
                })
                .catch(error => error && null)
        },
        replaceQuote: function(params) {
            const state = params.status ? params.status : ''
            const quote = params.quote ? params.quote : {}
            let index
            if (state == 'OPEN') {
                index = this.quotesOpen.findIndex(q => q.id === quote.id)
                this.quotesOpen.splice(index, 1, quote)
                this.quotesOpen = _.cloneDeep(this.quotesOpen)
            } else if (state == 'SEND') {
                index = this.quotesSent.findIndex(q => q.id === quote.id)
                this.quotesSent.splice(index, 1, quote)
                this.quotesSent = _.cloneDeep(this.quotesSent)
            } else if (state == 'APPROVAL') {
                index = this.quotesApproval.findIndex(q => q.id === quote.id)
                this.quotesApproval.splice(index, 1, quote)
                this.quotesApproval = _.cloneDeep(this.quotesApproval)
            }
        },
        quotesByAgesDialog() {
            this.openQuotesByAgesDialog = true
            this.getUsers()
            this.$router.push({
                name: 'quotesByAges',
                params: {
                    quotes: this.filteredQuotesSent,
                    users: this.users,
                },
            })
            this.loading = false
        },
        updateChanges(quote) {
            this.cardQuote = _.cloneDeep(quote)
            this.cardQuote.status = 'AWARDED'
            const index = this.quotesSent.findIndex(
                q => q.id == this.cardQuote.id
            )
            this.quotesSent.splice(index, 1)
            this.quoteToArchive = this.cardQuote
            this.closeConfirmAwarded()
        },
        closeConfirmAwarded() {
            this.selectedQuote = {}
            this.confirmAwardedDialog = false
        },
    },
    async created() {
        try {
            await this.getUsers()
            await this.getQuotes()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    updated() {
        window.addEventListener('popstate', () => {
            if (this.$router.currentRoute.name == 'main board') {
                this.backToMainBoard()
            }
        })
    },
    async mounted() {
        try {
            this.seeFilters = false
            this.errorMsg = ''
            await this.getClients()
            const {
                data: { user },
            } = await API.getMyInfo()
            const createQuotes = user.permissions.find(x => x == 'createQuotes')
            if (createQuotes) {
                this.createQuotes = true
            }
            const createHistory = user.permissions.find(
                x => x == 'createHistory'
            )
            if (createHistory) {
                this.createHistory = true
            }
            // set client
            if (this.getClientQuote) {
                this.openQuote(this.getClientQuote, this.costs)
            }
            this.setClientQuote(undefined)
            this.onResize()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.seeFilters = true
        }
    },
}
</script>

<style scoped>
.borderRounded {
    border-radius: 10px;
}
.banner >>> .v-banner__wrapper {
    border-bottom: 0px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
}
</style>
